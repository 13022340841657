<template>
  <a-layout id="components-layout-demo-side" style="min-height: 100vh;">

    <a-layout-sider v-model="collapsed" collapsible>
      <div class="logo">
        <img src="../../static/logo.png" alt="logo" />
        <h1>宣导资料管理</h1>
      </div>
      <a-menu theme="dark" :default-selected-keys="['1']" mode="inline">
        <a-sub-menu key="1">
          <span slot="title"><a-icon  /><span>宣导资料管理</span></span>
          <a-menu-item  @click="goTo('/bsource','资料','资料管理')" key="sub1">
            资料管理
          </a-menu-item>
          <a-menu-item  @click="goTo('/sourceclass','资料分类','分类管理')" key="sub2">
            分类管理
          </a-menu-item>
          <a-menu-item @click="goTo('/member','会员','会员管理')" key="sub3">
          会员管理
          </a-menu-item>
       
          <a-menu-item @click="goTo('/card','卡片','卡片管理')" key="sub4">
            卡片管理
          </a-menu-item>
          <a-menu-item @click="goTo('/membershare','会员分享','会员分享')" key="sub7">
            会员分享
          </a-menu-item>
          <a-menu-item @click="goTo('/company','公司','公司管理')" key="sub6">
            公司管理
          </a-menu-item>
          <a-menu-item @click="goTo('/cardhistory','卡片','领卡记录')" key="sub5">
            领卡记录
          </a-menu-item>

        </a-sub-menu>

      </a-menu>
    </a-layout-sider>
    <a-layout >
      <a-layout-header style="background: #fff;padding: 0;" >

        <div class="wrpCls">
          <a-dropdown>
                    <span class="ant-pro-account-avatar">
                  <a-avatar size="small" src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png" class="antd-pro-global-header-index-avatar" />
                  <span></span>
                </span>
            <a-menu slot="overlay" >


              <a-menu-item @click="logout()" key="2"> <a-icon type="logout" />退出</a-menu-item>

            </a-menu>
            <a-icon slot="icon" type="user" />
          </a-dropdown>
        </div>



      </a-layout-header>
      <a-layout-content style="margin: 0 16px">
        <a-breadcrumb style="margin:5px 0;">
          <a-breadcrumb-item>{{firstmenu}}</a-breadcrumb-item>
          <a-breadcrumb-item>{{secondmenu}}</a-breadcrumb-item>
        </a-breadcrumb>
        <router-view>

        </router-view>
      </a-layout-content>
      <a-layout-footer style="text-align: center">
        实益拓展
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
export default {
  name: "index",
  data(){
    return {
      visible:false,
      menus:[],
      collapsed:false,

      firstmenu:'资料管理',
      secondmenu:'列表',
      admin:{},

    }
  },
  created(){


  },
  mounted(){
    this.$router.push({path:'bsource'});
  },
  methods:{
    goTo(path,fmenu,smenu){
      this.firstmenu=fmenu;
      this.secondmenu=smenu;
      this.$router.push({path:path});
    },
    logout(){
      this.$store.commit('setUser',null);
      this.$router.push({name:'/'});
    }

  },
  computed:{

  }
}
</script>

<style lang="less" scoped>

#components-layout-demo-side .logo{
  margin: 16px;


  position: relative;
  display: flex;


  cursor: pointer;
  transition: padding .3s cubic-bezier(.645,.045,.355,1);
  img{
    height: 32px;
    vertical-align: middle;
    display: inline-block;

  }
  h1{
    height: 32px;
    color: #fff;
    font-weight: 600;
    display: inline-block;
    margin-left: 12px;
    line-height: 32px;
    vertical-align: middle;

  }
}
.wrpCls{
  position: relative;
  display: inline-flex;
  float: right;
}
::v-deep .ant-layout-header{
  height: 35px;
  line-height: 35px;
}
::v-deep .ant-pro-account-avatar{
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 22px;
  cursor: pointer;

}

</style>